import { template as template_1fbae1f32c0a4bd9932b76252bddd306 } from "@ember/template-compiler";
const FKLabel = template_1fbae1f32c0a4bd9932b76252bddd306(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
