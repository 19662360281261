import { template as template_b029f1b4345940e39c2de1b46cf3e571 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b029f1b4345940e39c2de1b46cf3e571(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
